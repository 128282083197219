import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';
import {
  Box,
  Heading,
  Text,
  FormControl,
  Input,
  Checkbox,
  FormLabel,
  Textarea,
  Button,
  Center,
  useToast,
} from '@chakra-ui/react';

import useFormFields from '@/hooks/useFormFields';
import useMutation from '@/hooks/useMutation';
import getTimeNow from '@invitato/helpers/dist/getTimeNow';

import { BG_COVER } from '@/constants/assets';
import { API_HOSTNAME, SHEET_GIFT_CONFIRMATION } from '@/constants';
import {
  ENABLE_SEND_GIFT,
  ENABLE_BANK_TRANSFER,
  DEFAULT_LANGUAGE,
} from '@/constants/feature-flags';
import txtWording from './locales';

const INPUT_COMMON_PROPS = {
  isRequired: true,
  size: 'md',
  type: 'text',
  variant: 'outline',
  colorScheme: 'blackAlpha',
  borderRadius: '0',
  backgroundColor: 'mainColorText',
  fontFamily: 'serif',
  color: 'secondaryColorText',
  _placeholder: { color: 'secondaryColorText' },
  _focus: { borderColor: 'secondaryColorText' },
};

const FORM_LABEL_PROPS = {
  fontSize: 'sm',
  color: 'secondaryColorText',
  margin: '8px 0 0 0',
  fontFamily: 'serif',
};

const CHECKBOX_PROPS = {
  fontSize: 'sm',
  color: 'secondaryColorText',
};

function Confirmation({ lang, onBack }) {
  const [loading, setLoading] = useState(false);
  const [isGiftCard, setIsGiftCard] = useState(false);
  const [isBankTransfer, setIsBankTransfer] = useState(false);
  const toast = useToast();

  const [onConfirm] = useMutation(
    API_HOSTNAME,
    SHEET_GIFT_CONFIRMATION,
    'insert_gift_confirmation',
  );
  const { formFields, createChangeHandler, onResetForm } = useFormFields({
    name: '',
    isGift: false,
    note: '',
    hp: '',
  });

  const onSendConfirmation = async () => {
    if (!formFields.name) {
      toast({
        title: 'Oops!',
        description: txtWording.fillNameFirst[lang],
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    // set loading state
    setLoading(true);

    const getYesNo = (p) => (p ? 'YA' : 'TIDAK');
    const query =
      `nama=${formFields.name}` +
      `&hp=${formFields.hp}` +
      `&catatan=${formFields.note}` +
      `&bank_transfer=${getYesNo(isBankTransfer)}` +
      `&hadiah_fisik=${getYesNo(isGiftCard)}` +
      `&waktu=${getTimeNow()}`;

    const result = await onConfirm(query);

    if (result.success) {
      toast({
        title: txtWording.submitted[lang],
        description: txtWording.successSent[lang],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      onResetForm();
    } else {
      toast({
        title: 'Oops!',
        description: txtWording.failedSent[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    setLoading(false);
  };

  return (
    <Box
      bgImage={`url(${BG_COVER})`}
      bgSize="cover"
      minHeight="100vh"
      bgPosition="center"
      padding="32px"
    >
      <Box bgColor="white" padding="24px 16px" borderRadius="16px" boxShadow="lg">
        {/* Heading & Desc Section */}
        <Box textAlign="center" color="mainColorText">
          <Heading color="secondaryColorText" fontWeight="normal" fontSize="4xl">
            {txtWording.title[lang]}
          </Heading>
          <Text color="secondaryColorText" fontSize="sm" padding="16px 0 " fontFamily="serif">
            {txtWording.desc[lang]}
          </Text>
        </Box>
        <Box>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.name[lang]}:</FormLabel>
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder="..."
              value={formFields.name}
              onChange={createChangeHandler('name')}
            />
          </FormControl>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.hp[lang]}:</FormLabel>
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder="..."
              value={formFields.hp}
              onChange={createChangeHandler('hp')}
            />
          </FormControl>
          <FormControl margin="24px 0" fontFamily="serif">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.giftType[lang]}:</FormLabel>
            {ENABLE_BANK_TRANSFER && (
              <Box>
                <Checkbox
                  value={isBankTransfer}
                  onChange={() => setIsBankTransfer(!isBankTransfer)}
                >
                  <Text {...CHECKBOX_PROPS}>{txtWording.bank[lang]}</Text>
                </Checkbox>
              </Box>
            )}
            {ENABLE_SEND_GIFT && (
              <Box>
                <Checkbox value={isGiftCard} onChange={() => setIsGiftCard(!isGiftCard)}>
                  <Text {...CHECKBOX_PROPS}>{txtWording.gift[lang]}</Text>
                </Checkbox>
              </Box>
            )}
          </FormControl>
          <FormControl margin="8px 0">
            <FormLabel {...FORM_LABEL_PROPS}>{txtWording.notes[lang]}:</FormLabel>
            <Textarea
              {...INPUT_COMMON_PROPS}
              value={formFields.note}
              placeholder="..."
              onChange={createChangeHandler('note')}
            />
          </FormControl>
        </Box>
        <Box marginTop="24px">
          <Center>
            <Button
              isLoading={loading}
              fontWeight="normal"
              size="sm"
              colorScheme="blackAlpha"
              bgColor="secondaryColorText"
              onClick={onSendConfirmation}
            >
              {txtWording.sendConfirm[lang]}
            </Button>
          </Center>
        </Box>
      </Box>
      <Box paddingTop="24px">
        <Center>
          <Button
            leftIcon={<BiArrowBack />}
            type="button"
            size="sm"
            fontWeight="normal"
            bgColor="bgSecodary"
            color="btnPrimaryColor"
            onClick={onBack}
          >
            {txtWording.backToHome[lang]}
          </Button>
        </Center>
      </Box>
    </Box>
  );
}

Confirmation.propTypes = {
  lang: string,
  onBack: func.isRequired,
};

Confirmation.defaultProps = {
  lang: DEFAULT_LANGUAGE,
};

export default Confirmation;
